/* eslint-disable consistent-return */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import axios from 'axios';
import { PuffLoader } from 'react-spinners';
import { Form, Formik } from 'formik';
import { ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline';
import 'react-toastify/dist/ReactToastify.css';
import Image from 'next/image';
import FormFieldAndError from './FormFieldAndError';

export default function Newsletter({ footer = false }) {
  const [btnWait, setBtnWait] = useState(false);
  const [btnSuccess, setBtnSuccess] = useState(false);

  const post = (values) => {
    if (btnWait) {
      return toast.error('Lo sentimos, hubo un error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }

    const data = {
      email: values.Email,
    };
    setBtnWait(true);

    axios({
      method: 'post',
      url: `${process.env.NEXT_PUBLIC_API}/newsletter`,
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        setBtnSuccess(true);
        toast.success('Su mensaje ha sido enviado', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((error) => {
        console.log(error);
        setBtnWait(false);

        toast.error('Lo sentimos, hubo un error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  const ContactSchema = Yup.object().shape({
    Email: Yup.string()
      .email('Debe ser un Email valido')
      .required('Requerido')
      .min(5, 'Debe tener al menos 5 caracteres')
      .max(125, 'No debe tener más de 75 caracteres'),
  });
  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {!footer ? (
        <div className="p-5 mt-10 bg-brand-brown/10 rounded-xl">
          <h2 className="mb-4 text-xl md:text-2xl payfair" data-aos="fade-up">
            Regístrate en el Newsletter
          </h2>
          <Formik
            initialValues={{
              Email: '',
            }}
            validationSchema={ContactSchema}
            onSubmit={post}
          >
            {({ errors, touched }) => (
              <Form action="#" noValidate className="flex flex-col gap-3 text-brand-main">
                <FormFieldAndError
                  labelText="Email"
                  btnSuccess={btnSuccess}
                  extraClass=""
                  errors={errors}
                  touched={touched}
                  name="Email"
                />
                <button
                  type="submit"
                  aria-label="Submit"
                  className={`relative mt-3 inline-block text-sm font-bold ml-auto col-span-full text-white bg-brand-main hover:bg-brand-dark/80   group w-fit ${
                    btnSuccess && 'pointer-events-none brightness-75'
                  }`}
                >
                  <span
                    className={`relative flex items-center justify-center  ${
                      btnSuccess || (btnWait && 'w-12 h-12')
                    }  text-white opensans-bold gap-x-2`}
                  >
                    <span className="flex items-center px-10 payfair gap-x-1 h-[45px]">
                      {btnSuccess ? (
                        <CheckIcon className="w-4 h-4" />
                      ) : (
                        !btnWait
                        && !btnSuccess && (
                          <>
                            Enviar
                            <ArrowRightIcon className="w-4 h-4" />
                          </>
                        )
                      )}
                      {btnWait && !btnSuccess && <PuffLoader size="20px" color="#fff" />}
                    </span>
                  </span>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <section className="relative z-10 overflow-hidden ">
          <div className="w-full max-w-xl  mx-auto md:max-w-5xl 2xl:max-w-7xl text-brand-main">
            <div className="flex flex-col  md:flex-row justify-between items-start md:items-center py-5 mb-10 gap-5">
              <div className="w-full md:w-fit">
                <h3
                  className="mb-4 text-xl md:text-xl text-brand-main-light payfair"
                  data-aos="fade-up"
                >
                  Regístrate en el Newsletter
                </h3>
                <div className=" flex  items-end  gap-5">
                  <Formik
                    initialValues={{
                      Email: '',
                    }}
                    validationSchema={ContactSchema}
                    onSubmit={post}
                  >
                    {({ errors, touched }) => (
                      <Form
                        action="#"
                        noValidate
                        className="flex items-end   gap-3 w-full  text-brand-main"
                      >
                        <FormFieldAndError
                          labelClassName="!text-white sr-only"
                          labelText="Email"
                          placeholder="Ingrese su email para suscribirse"
                          btnSuccess={btnSuccess}
                          extraClass="border-brand-main-light w-full md:w-96  !h-[45px] text-white"
                          errors={errors}
                          touched={touched}
                          name="Email"
                        />
                        <button
                          type="submit"
                          aria-label="Submit"
                          className={`relative  inline-block text-sm font-bold ml-auto !h-[45px] col-span-full text-white bg-brand-btn hover:bg-brand-dark/80   group w-fit ${
                            btnSuccess && 'pointer-events-none brightness-75'
                          }`}
                        >
                          <span
                            className={`relative flex items-center justify-center  ${
                              btnSuccess || (btnWait && 'w-12 h-12')
                            }  text-white opensans-bold gap-x-2`}
                          >
                            <span className="flex items-center px-5 md:px-10 payfair gap-x-1 h-[40px] md:h-[45px]">
                              {btnSuccess ? (
                                <CheckIcon className="w-4 h-4" />
                              ) : (
                                !btnWait
                                && !btnSuccess && (
                                  <>
                                    Enviar
                                    <ArrowRightIcon className="w-4 h-4" />
                                  </>
                                )
                              )}
                              {btnWait && !btnSuccess && <PuffLoader size="20px" color="#fff" />}
                            </span>
                          </span>
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="flex order-first md:order-none flex-col">
                <div className="relative  w-40 h-12   lg:h-24 sm:w-40 lg:w-72 ">
                  <Image
                    fill
                    src="/images/jada-logo.svg"
                    alt="Logo EntreCoach"
                    className="object-contain object-center"
                    sizes="120px"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

Newsletter.propTypes = {
  footer: PropTypes.bool,
};
